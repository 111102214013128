#labelsContainer {
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin-top: 24px;

    .itensGroup {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        gap: 8px;
        width: 50%;

        span {
            color: var(--color-text-p);
            font-size: 14px;
            font-weight: bold;
        }

    }
    
    .boxLabel {
        width: auto;
        padding: 6px 12px;
        border: 1px solid var(--color-divider);
        border-radius: 15px;

        color: var(--color-text-p-lm);
        font-size: 14px;

        &.label-4, &.label-5, &.label-6, &.label-7, &.label-9, &.label-10 {
            color: var(--color-text-p-dm);
        } 
    
        &.label-1 {
            background-color: var(--color-label-scheduling-1);
        }
    
        &.label-2 {
            background-color: var(--color-label-scheduling-2);
        }
    
        &.label-3 {
            background-color: var(--color-label-scheduling-3);
        }
    
        &.label-4 {
            background-color: var(--color-label-scheduling-4);
        }
    
        &.label-5 {
            background-color: var(--color-label-scheduling-5);
        }
    
        &.label-6 {
            background-color: var(--color-label-scheduling-6);
        }
    
        &.label-7 {
            background-color: var(--color-label-scheduling-7);
        }
    
        &.label-8 {
            background-color: var(--color-label-scheduling-8);
        }
    
        &.label-9 {
            background-color: var(--color-label-scheduling-9);
        }
    
        &.label-10 {
            background-color: var(--color-label-scheduling-10);
        }

    }

    .boxStatus {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;

        color: var(--color-text-s);

        &.status-1 {
            fill: var(--color-status-scheduling-1);
        }
    
        &.status-2 {
            fill: var(--color-status-scheduling-2);
        }
    
        &.status-3 {
            fill: var(--color-status-scheduling-3);
        }
    
        &.status-4 {
            fill: var(--color-status-scheduling-4);
        }
    
        &.status-5 {
            fill: var(--color-status-scheduling-5);
        }
    
        &.status-6 {
            fill: var(--color-status-scheduling-6);
        }
    
        &.status-7 {
            fill: var(--color-status-scheduling-7);
        }
    
        &.status-8 {
            fill: var(--color-status-scheduling-8);
        }

        svg {

            path {
                fill: inherit !important;
            }

        }

    }

}

.textGroup.agendamentoConsulta {
    display: flex;
    flex-direction: column;

    span {

        &:nth-of-type(1){
            color: var(--color-text-p);
        }

        &:nth-of-type(2){
            color: var(--color-text-t);
        }

    }

}