#formLoginContainer {
    display: grid;
    grid-template-columns: 1fr auto;
    height: 100vh;
    background-color: var(--color-layout-foreground-lm);

    overflow: hidden;

    .loginContent {
        position: relative;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 80px;
    
        &:nth-child(1){
            align-items: flex-start;
            padding: 0;
    
            h1, h2 {
                color: var(--color-text-p-dm);
                font-size: 40px;
                text-align: left;
            }
    
            h1 {
                font-weight: bold;
            }

            .loginGradient {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-grow: 1;
                width: 100%;
                height: 100%;
                background: #9461A6;
                //background: linear-gradient(180deg, rgba(208,82,251,1) 0%, rgba(17,19,40,1) 100%);
                background-image: url('../../../../assets/images/fundo.png');
                background-size: cover;

                img {
                    width: 200px;
                }

            }
    
        }
    
        &:nth-child(2){
            background-color: var(--color-layout-p-lm);
    
            form {
                width: auto !important;
    
                #formContainer {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 24px;
                    width: 320px;

                    .logoContainer {
                        width: 100%;

                        span {
                            color: #A5A5A5;
                            font-size: 22px;
                            font-weight: bold;
                        }

                    }
    
                    img {
                        margin-bottom: 24px;
                    }

                    .message {
                        width: 100%;

                        color: var(--color-status-error-p);
                        text-align: center;
                    }

                    .forgotPWD {
                        color: var(--color-text-t-lm);

                        cursor: pointer;
                        transition: var(--transition-default);

                        &:hover {
                            color: var(--color-text-p-lm);
                        }

                    }
    
                    button {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        gap: 16px;
                        width: 100%;
                        padding: 8px 32px;
                        border-color: var(--color-status-success-p);
                        border-radius: 8px;
                        background-color: var(--color-status-success-p);
    
                        color: var(--color-text-p-dm);
                        font-size: 14px;
                        font-weight: bold;
    
                        cursor: pointer;
                        transition: var(--transition-default);
    
                        &:hover {
                            border-color: var(--color-status-success-t);
                            background-color: var(--color-status-success-t);
                        }

                        &.status {

                            &.disabled {
                                pointer-events: none;
                            }

                        }

                        .spinner {
                            width: 18px;
                            height: 18px;
                            border: 3px solid var(--color-text-p-dm);
                            border-right-color: transparent;
                            border-radius: 50%;

                            -webkit-animation: spinnerAnimation 0.75s linear infinite;
                            animation: spinnerAnimation 0.75s linear infinite;
                        }
    
                    }
    
                    a {
                        color: var(--color-text-t-lm);
                        font-size: 14px;
                        font-weight: bold;
                        text-decoration: underline;
    
                        &:hover {
                            color: var(--color-text-s-lm);
                        }
    
                    }
            
                }
    
            }
    
        }
    
        .custom-shape-divider-top-1640025532 {
            width: calc(100vh + 1px);
            height: 200px;
    
            line-height: 0;
    
            transform: rotate(270deg);
            overflow: hidden;
    
            svg {
                position: relative;
        
                display: block;
                width: 100%;
                height: 100%;
    
                path {
                    fill: var(--color-theme-p);
                }
    
            }
    
        }
    
    }

}

@keyframes spinnerAnimation {
    0% {
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.qas {

    #formLoginContainer {

        .loginContent {

            &:nth-child(1){
    
                .loginGradient {
                    background: var(--color-status-warning-p);
                    background-image: '';
                    background: linear-gradient(180deg, var(--color-status-warning-p) 0%, var(--color-status-warning-t) 100%);
                }
        
            }

            &:nth-child(2){
        
                form {
        
                    #formContainer {

                    }

                }

            }

        }

    }

}