.painelBox {
    width: 110px;
    height: 60px;
    border-radius: 8px;
    box-shadow: var(--color-shadow-s);
    
    color: var(--color-text-p-lm);

    &.label-4, &.label-5, &.label-6 {
        color: var(--color-text-p-dm);
    } 

    &.label-1 {
        background-color: var(--color-label-scheduling-1);
    }

    &.label-2 {
        background-color: var(--color-label-scheduling-2);
    }

    &.label-3 {
        background-color: var(--color-label-scheduling-3);
    }

    &.label-4 {
        background-color: var(--color-label-scheduling-4);
    }

    &.label-5 {
        background-color: var(--color-label-scheduling-5);
    }

    &.label-6 {
        background-color: var(--color-label-scheduling-6);
    }

    &.label-7 {
        background-color: var(--color-label-scheduling-7);
    }

    &.label-8 {
        background-color: var(--color-label-scheduling-8);
    }

    &.label-9 {
        background-color: var(--color-label-scheduling-9);
    }

    &.label-10 {
        background-color: var(--color-label-scheduling-10);
    }

    &.status-1 {
        fill: var(--color-status-scheduling-1);
    }

    &.status-2 {
        fill: var(--color-status-scheduling-2);
    }

    &.status-3 {
        fill: var(--color-status-scheduling-3);
    }

    &.status-4 {
        fill: var(--color-status-scheduling-4);
    }

    &.status-5 {
        fill: var(--color-status-scheduling-5);
    }

    &.status-6 {
        fill: var(--color-status-scheduling-6);
    }

    &.status-7 {
        fill: var(--color-status-scheduling-7);
    }

    &.status-8 {
        fill: var(--color-status-scheduling-8);
    }

    .painelBoxTouchable {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        flex: 1;
        gap: 4px;
        padding: 4px;
        border-radius: inherit;

        cursor: pointer;

        &:hover {
            background-color: var(--color-action-hover);
        }

        span {
            color: inherit;
            font-size: 16px;
            text-align: center;

            &.text {
                width: 100%;
            }

        }
    
        svg path {
            fill: inherit !important;
        }

    }

}